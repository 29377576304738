<template>
  <div class="property-carousel-wrapper" :style="{ height: `${height}px` }">
    <div
      class="property-carousel-main-image"
      :style="{
        backgroundImage: `url(${
          vrCovers[vrType] || resolveImageUrl(list[currentIndex])
        })`,
      }"
      @click="openPreviewBoard(currentIndex)"
    >
      <div class="vr-mask" v-if="vrType !== ''">
        <img
          v-if="vrType === 'rawVr'"
          :src="require('../../../assets/detail/vr-cover.png')"
          style="width: 120px"
        />
        <img
          v-if="vrType === 'drone'"
          :src="require('../../../assets/detail/drone-cover.png')"
          style="width: 150px"
        />
        <img
          v-if="vrType === 'enovation'"
          :src="require('../../../assets/detail/renovation-cover.png')"
          style="width: 150px"
        />
        <img
          v-if="vrType === 'video'"
          :src="require('../../../assets/detail/video-tour-cover.png')"
          style="width: 150px"
        />
      </div>
    </div>
    <div class="property-image-list">
      <!-- 实现横向图片列表并且可以左右滑动，两边有按钮可以操作滑动 -->
      <!-- <div class="left-button"></div>
      <div class="right-button"></div> -->
      <div
        :style="{
          width: `${(list.length + this.extNum) * 118}px`,
          textAlign: 'left',
          marginLeft: '30px',
          marginRight: '30px',
        }"
      >
        <!-- 图 vr 1 -->
        <div
          v-if="hasVr"
          class="property-image-item"
          :style="{
            backgroundImage: `url(${vrInfo?.vrCoverUrl})`,
            opacity: isVr ? 1 : 0.5,
            opacity: vrType === 'rawVr' ? 1 : 0.5,
          }"
          @click="handleClickVr"
        >
          <div class="vr-mask">
            <img
              :src="require('../../../assets/detail/vr-small.png')"
              style="width: 40px"
            />
          </div>
        </div>
        <!-- 图 vr 无人机 -->
        <div
          v-if="hasDrone"
          class="property-image-item"
          :style="{
            backgroundImage: `url(${vrInfo?.droneVersionCoverUrl})`,
            opacity: vrType === 'drone' ? 1 : 0.5,
          }"
          @click="handleClickDrone"
        >
          <div class="vr-mask">
            <img
              :src="require('../../../assets/detail/drone-small.png')"
              style="width: 32px"
            />
          </div>
        </div>
        <!-- 图 vr 装修 -->
        <div
          v-if="hasenovation"
          class="property-image-item"
          :style="{
            backgroundImage: `url(${vrInfo?.vrenovationCoverUrl})`,
            opacity: vrType === 'enovation' ? 1 : 0.5,
          }"
          @click="handleClickEnovation"
        >
          <div class="vr-mask">
            <img
              :src="require('../../../assets/detail/ai-small.png')"
              style="width: 40px"
            />
          </div>
        </div>
        <!-- vr video -->
        <div
          v-if="hasVideoTour"
          class="property-image-item"
          :style="{
            backgroundImage: `url(${vrInfo?.videoTourCoverUrl})`,
            opacity: vrType === 'video' ? 1 : 0.5,
          }"
          @click="handleVideoTour"
        >
          <div class="vr-mask">
            <img
              :src="require('../../../assets/detail/video-small.png')"
              style="width: 40px"
            />
          </div>
        </div>
        <!-- 图片列表 -->
        <div
          v-for="(item, index) in list"
          :key="item.baseUrl"
          class="property-image-item"
          :style="{
            backgroundImage: `url(${resolveImageUrl(item, false)})`,
            opacity: vrType === '' && currentIndex == index ? 1 : 0.5,
          }"
          @click="handleClickImageItem(index)"
        ></div>
      </div>
    </div>
    <preview-image
      ref="preview"
      v-if="list.length > 0"
      :imgsUrls="previewUrls"
      :visible="showPreview"
      @close="closePreview"
    />
  </div>
</template>

<script>
import previewImage from "@/components/previewImage";

export default {
  name: "PropertyCarousel",
  components: {
    previewImage,
  },
  props: {
    imgList: {
      type: Array,
      value: [],
    },
    serverDomain: {
      type: String,
      value: "",
    },
    vrUrl: {
      type: String,
      value: "",
    },
    vrInfo: {
      type: Object,
      value: {
        vrUrl: null,
        droneVersionCoverUrl: null,
        droneVersionUrl: null,
        houseId: null,
        vrCoverUrl: null,
        vrenovationCoverUrl: null,
        vrenovationUrl: null,
      },
    },
  },
  data() {
    return {
      hasVr: false,
      hasenovation: false,
      hasDrone: false,
      hasVideoTour: false,
      extNum: 0,
      showPreview: false,
      currentIndex: 0,
      previewUrls: [],
      height: 0,
      list: [],
      vrType: "",
      vrCovers: {
        rawVr: "",
        enovation: "",
        drone: "",
        videoTourUrl: "",
      },
    };
  },
  mounted() {},
  watch: {
    imgList(newVal) {
      if (newVal) {
        if (!newVal) {
          return;
        }
        if (newVal.length === 0) {
          return;
        }
        const width = document.querySelector(
          ".property-carousel-wrapper"
        ).offsetWidth;
        this.height = width * 0.66;
        const previewUrls = newVal.map((img) => {
          return this.resolveImageUrl(img);
        });
        this.list = newVal;
        this.previewUrls = previewUrls;
        console.log(this.list, "list");
      }
    },
    vrInfo(newVal) {
      let vrType = "";
      if (newVal.videoTourUrl) {
        this.hasVideoTour = true;
        this.vrCovers.videoTourUrl = newVal.videoTourUrl;
        vrType = "video";
      }
      if (newVal.vrenovationCoverUrl) {
        this.hasenovation = true;
        this.vrCovers.enovation = newVal.vrenovationCoverUrl;
        vrType = "enovation";
      }
      if (newVal.droneVersionCoverUrl) {
        this.hasDrone = true;
        this.vrCovers.drone = newVal.droneVersionCoverUrl;
        vrType = "drone";
      }
      if (newVal.vrCoverUrl) {
        this.hasVr = true;
        this.vrCovers.rawVr = newVal.vrCoverUrl;
        vrType = "rawVr";
      }
      this.vrType = vrType;
    },
    hasVr(newVal) {
      if (newVal) {
        this.extNum = this.extNum + 1;
      }
    },
    hasenovation(newVal) {
      if (newVal) {
        this.extNum = this.extNum + 1;
      }
    },
    hasDrone(newVal) {
      if (newVal) {
        this.extNum = this.extNum + 1;
      }
    },
    hasVideoTour(newVal) {
      if (newVal) {
        this.extNum = this.extNum + 1;
      }
    },
  },
  methods: {
    resolveImageUrl(img) {
      if (!img) {
        return "";
      }
      if (img.baseUrl.indexOf("http") != -1) {
        return img.baseUrl;
      } else {
        if (!this.serverDomain) {
          return "";
        }
        return this.serverDomain + img.baseUrl + ".crop.1660x948.jpg";
      }
    },
    /**
     * @desc
     */
    openPreviewBoard(index = 0) {
      if (this.vrType !== "") {
        if (this.vrType === "rawVr") {
          window.open(this.vrInfo.vrUrl);
        }
        if (this.vrType === "drone") {
          window.open(this.vrInfo.droneVersionUrl);
        }
        if (this.vrType === "enovation") {
          window.open(this.vrInfo.vrenovationUrl);
        }
        if (this.vrType === "video") {
          window.open(this.vrInfo.videoTourUrl);
        }
        return;
      }
      this.showPreview = true;
      this.$refs.preview.switchCurrent(index);
    },
    closePreview() {
      this.showPreview = false;
    },
    handleClickImageItem(index) {
      this.vrType = "";
      this.currentIndex = index;
    },
    handleClickDrone() {
      this.vrType = "drone";
    },
    handleClickEnovation() {
      this.vrType = "enovation";
    },
    handleClickVr() {
      this.vrType = "rawVr";
    },
    handleVideoTour() {
      this.vrType = "video";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./propertyCarousel.scss";
</style>