var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"property-carousel-wrapper",style:({ height: `${_vm.height}px` })},[_c('div',{staticClass:"property-carousel-main-image",style:({
      backgroundImage: `url(${
        _vm.vrCovers[_vm.vrType] || _vm.resolveImageUrl(_vm.list[_vm.currentIndex])
      })`,
    }),on:{"click":function($event){return _vm.openPreviewBoard(_vm.currentIndex)}}},[(_vm.vrType !== '')?_c('div',{staticClass:"vr-mask"},[(_vm.vrType === 'rawVr')?_c('img',{staticStyle:{"width":"120px"},attrs:{"src":require('../../../assets/detail/vr-cover.png')}}):_vm._e(),(_vm.vrType === 'drone')?_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require('../../../assets/detail/drone-cover.png')}}):_vm._e(),(_vm.vrType === 'enovation')?_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require('../../../assets/detail/renovation-cover.png')}}):_vm._e(),(_vm.vrType === 'video')?_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require('../../../assets/detail/video-tour-cover.png')}}):_vm._e()]):_vm._e()]),_c('div',{staticClass:"property-image-list"},[_c('div',{style:({
        width: `${(_vm.list.length + this.extNum) * 118}px`,
        textAlign: 'left',
        marginLeft: '30px',
        marginRight: '30px',
      })},[(_vm.hasVr)?_c('div',{staticClass:"property-image-item",style:({
          backgroundImage: `url(${_vm.vrInfo?.vrCoverUrl})`,
          opacity: _vm.isVr ? 1 : 0.5,
          opacity: _vm.vrType === 'rawVr' ? 1 : 0.5,
        }),on:{"click":_vm.handleClickVr}},[_c('div',{staticClass:"vr-mask"},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require('../../../assets/detail/vr-small.png')}})])]):_vm._e(),(_vm.hasDrone)?_c('div',{staticClass:"property-image-item",style:({
          backgroundImage: `url(${_vm.vrInfo?.droneVersionCoverUrl})`,
          opacity: _vm.vrType === 'drone' ? 1 : 0.5,
        }),on:{"click":_vm.handleClickDrone}},[_c('div',{staticClass:"vr-mask"},[_c('img',{staticStyle:{"width":"32px"},attrs:{"src":require('../../../assets/detail/drone-small.png')}})])]):_vm._e(),(_vm.hasenovation)?_c('div',{staticClass:"property-image-item",style:({
          backgroundImage: `url(${_vm.vrInfo?.vrenovationCoverUrl})`,
          opacity: _vm.vrType === 'enovation' ? 1 : 0.5,
        }),on:{"click":_vm.handleClickEnovation}},[_c('div',{staticClass:"vr-mask"},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require('../../../assets/detail/ai-small.png')}})])]):_vm._e(),(_vm.hasVideoTour)?_c('div',{staticClass:"property-image-item",style:({
          backgroundImage: `url(${_vm.vrInfo?.videoTourCoverUrl})`,
          opacity: _vm.vrType === 'video' ? 1 : 0.5,
        }),on:{"click":_vm.handleVideoTour}},[_c('div',{staticClass:"vr-mask"},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require('../../../assets/detail/video-small.png')}})])]):_vm._e(),_vm._l((_vm.list),function(item,index){return _c('div',{key:item.baseUrl,staticClass:"property-image-item",style:({
          backgroundImage: `url(${_vm.resolveImageUrl(item, false)})`,
          opacity: _vm.vrType === '' && _vm.currentIndex == index ? 1 : 0.5,
        }),on:{"click":function($event){return _vm.handleClickImageItem(index)}}})})],2)]),(_vm.list.length > 0)?_c('preview-image',{ref:"preview",attrs:{"imgsUrls":_vm.previewUrls,"visible":_vm.showPreview},on:{"close":_vm.closePreview}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }